/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.ios ion-menu-button {
    --color:white;
}

.logoEron{
    width:40px!important;
    height:40px!important;
    padding:2px;
  }

  .no-scroll {
    --offset-bottom: auto!important;
    --overflow: hidden;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
}

.alert-wrapper {
  width: 100% !important;
  max-width: 100% !important;
  }

.menu-content-open{
  pointer-events: all !important;
}

/* Declare text styles */
h1 {
  font-size: 2em;
}
h2 {
  font-size: 1.5em;
}
p {
  font-size: 1em;
}

/* Increase font sizes by 1.5x on bigger screens */
@media (min-width: 48rem) {
  h1 {
    font-size: 3em;
  }
  h2 {
    font-size: 2.25em;
  }
  p {
    font-size: 1.5em;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #003478;
  -webkit-text-fill-color: #003478;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}